import { useSelector } from 'react-redux';
import { useGetEntriesQuery } from '../api/registry';
import MDTypography from 'components/material/MDTypography';

export const useRegistry = () => {
  const { filters } = useSelector((state) => state.registry);
  const { data, isLoading } = useGetEntriesQuery(filters);

  return {
    isLoading,
    columns: [
      { Header: 'Nr. Inregistrare', accessor: 'id', align: 'left' },
      { Header: 'Data inregistrarii', accessor: 'registryDate', align: 'left' },
      { Header: 'Nr. document ', accessor: 'documentNumber', aligh: 'left' },
      { Header: 'Emitent', accessor: 'issuer', aligh: 'left' },
      { Header: 'Continut', accessor: 'documentContent', aligh: 'left' },
      { Header: 'Destinatar', accessor: 'recipient', aligh: 'left' },
      { Header: 'Observatii ', accessor: 'observations', aligh: 'left' },
    ],
    rows: isLoading
      ? []
      : data.map((entry) => ({
          entry: {
            id: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {entry.id}
              </MDTypography>
            ),
            registryDate: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {new Date(entry.registryDate).toLocaleDateString('en-GB')}
              </MDTypography>
            ),
            documentNumber: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {entry.documentNumber}
              </MDTypography>
            ),
            issuer: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {entry.issuer}
              </MDTypography>
            ),
            documentContent: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {entry.documentContent}
              </MDTypography>
            ),
            recipient: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {entry.recipient}
              </MDTypography>
            ),
            observations: (
              <MDTypography variant="caption" color="text" fontWeight="medium"></MDTypography>
            ),
          },
        })),
  };
};
