import DashboardLayout from 'components/layouts/DashboardLayout';
import DashboardNavbar from 'components/navbar/DashboardNavbar/TopNavbar';
import PollsTable from 'screens/polls/components/PollsTable';

const MemberDashboardScreen = () => {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <PollsTable memberView />
    </DashboardLayout>
  );
};

export default MemberDashboardScreen;
