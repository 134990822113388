import { baseApi } from 'api';

const registryApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getEntries: builder.query({
      query: ({ region }) => {
        let url = '/registry';
        if (region) url += `?region=${region}`;
        return url;
      },
      provideTags: ['Entries'],
    }),

    getAddresses: builder.query({
      query: () => '/registry/address',
      provideTags: ['Addresses'],
    }),

    createAddress: builder.mutation({
      query: (data) => ({
        url: '/registry/address',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Addresses'],
    }),
  }),
});

export const { useGetEntriesQuery, useGetAddressesQuery, useCreateAddressMutation } = registryApi;
