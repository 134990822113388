import React from 'react';

import DashboardLayout from 'components/layouts/DashboardLayout';
import DashboardNavbar from 'components/navbar/DashboardNavbar/TopNavbar';
import PollsTable from './components/PollsTable';

const PollsScreen = () => {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <PollsTable />
    </DashboardLayout>
  );
};

export default PollsScreen;
