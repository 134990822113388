import Grid from '@mui/material/Grid';

// Material Dashboard 2 React components
import MDBox from 'components/material/MDBox';

import DashboardLayout from 'components/layouts/DashboardLayout';
import DashboardNavbar from 'components/navbar/DashboardNavbar/TopNavbar';
import StatisticsCard from 'components/common/StatisticsCard';

// Dashboard components
import MembersTable from 'screens/dashboard/components/MembersTable';
import { useSelector } from 'react-redux';
import { useGetPollsQuery } from 'api/polls';

function Dashboard() {
  const members = useSelector((state) => state.user.users);
  const { data, isLoading: pollsLoading } = useGetPollsQuery();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <StatisticsCard
                color="dark"
                icon="weekend"
                title="Poll-uri"
                count={pollsLoading ? '...' : data?.length}
              ></StatisticsCard>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <StatisticsCard
                icon="leaderboard"
                title="Membrii"
                count={members?.length}
              ></StatisticsCard>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <StatisticsCard
                color="success"
                icon="store"
                title="Unitati"
                count="-"
              ></StatisticsCard>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <StatisticsCard
                color="primary"
                icon="person_add"
                title="Administratori"
                count="-"
              ></StatisticsCard>
            </MDBox>
          </Grid>
        </Grid>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <MembersTable />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default Dashboard;
