import React from 'react';

import DashboardLayout from 'components/layouts/DashboardLayout';
import DashboardNavbar from 'components/navbar/DashboardNavbar/TopNavbar';
import { CreatePollForm } from './components/CreatePollsForm';

const CreatePollScreen = () => {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <CreatePollForm />
    </DashboardLayout>
  );
};

export default CreatePollScreen;
