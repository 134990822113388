const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
  filters: {
    region: 0,
  },
};

const registrySlice = createSlice({
  name: 'registry',
  initialState,
  reducers: {
    setRegistryFilters(state, action) {
      state.filters = action.payload;
    },
  },
});

export const { setRegistryFilters } = registrySlice.actions;

export default registrySlice.reducer;
