import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Card from '@mui/material/Card';
import MDBox from 'components/material/MDBox';

// Data
import useMembers from 'hooks/useMembers';
import { useNavigate } from 'react-router-dom';
import BasicTable from 'components/common/Table';
import { useSelector } from 'react-redux';
import { UserRoles } from 'constants/UserRoles';
import TbaleHeader from './TableHeader';
import BasicModal from 'components/common/Modal';
import { useDeleteUserMutation } from 'api/user';
import MDButton from 'components/material/MDButton';
import styled from 'styled-components';

function Projects() {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentUser, setCurrentUser] = useState();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const [deleteUser, info] = useDeleteUserMutation();

  const handleDeleteUser = (user) => {
    setShowDeleteModal(true);
    setCurrentUser(user);
    // deleteUser(user.id);
  };

  useEffect(() => {
    if (info.isSuccess) toast.success('Membru sters cu succes!');
    if (info.isError) toast.error('A aparut o problema, va rugam incercati mai tarziu');
    setShowDeleteModal(false);
  }, [info]);

  const { columns, rows, isLoading } = useMembers(handleDeleteUser);
  useEffect(() => {
    if (!user) return navigate('/authentication/signin');
    if (user.roles.map((ur) => ur.role).includes(UserRoles.Member)) {
      navigate('/dashboard/general');
    }
  }, [user]);

  if (isLoading) return <p>Loading ...</p>;

  return (
    <Card>
      <BasicModal
        open={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false);
        }}
      >
        Adaugati o inregistrare tip "iesire sindicat" in registru?
        <ButtonsContainer>
          <MDButton
            style={{ flex: 1 }}
            color="primary"
            onClick={() => {
              deleteUser({ id: currentUser.id, body: { withEntry: true } });
            }}
          >
            Da
          </MDButton>
          <MDButton
            style={{ flex: 3 }}
            color="error"
            onClick={() => {
              deleteUser({ id: currentUser.id, body: { withEntry: true } });
            }}
          >
            Nu, doar sterge membru
          </MDButton>
        </ButtonsContainer>
      </BasicModal>
      <MDBox>
        <TbaleHeader />
        <BasicTable usersTable={true} pageSize={300} rows={rows} columns={columns} />
      </MDBox>
    </Card>
  );
}

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  gap: 16px;
`;

export default Projects;
