import { baseApi } from 'api';
const countyApiSlice = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAccessibleCounties: builder.query({
      query: () => '/county',
    }),
  }),
});

export const { useGetAccessibleCountiesQuery } = countyApiSlice;
