import React from 'react';
import Spinner from 'react-spinners/BeatLoader';
import styled from 'styled-components';

const StyledContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const Loader = () => {
  return (
    <StyledContainer>
      <Spinner color={'#49a3f1'} loading={true} size={14} />
    </StyledContainer>
  );
};
