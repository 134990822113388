import React, { useEffect, useState } from 'react';
import DashboardLayout from 'components/layouts/DashboardLayout';
import DashboardNavbar from 'components/navbar/DashboardNavbar/TopNavbar';
import MDBox from 'components/material/MDBox';
import MDInput from 'components/material/MDInput';
import MDButton from 'components/material/MDButton';
import { useUpdateRegionByIdMutation, useGetRegionByIdQuery } from 'api/region';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const EditRegionScreen = () => {
  const [region, setRegion] = useState();
  const { id } = useParams();

  const { data, isLoading: getLoading } = useGetRegionByIdQuery(id);
  const [updateRegion, { isLoading: loadingSave, isError, isSuccess }] =
    useUpdateRegionByIdMutation();

  const handleSubmit = () => {
    updateRegion({ id, data: { name: region.name, fullName: region.fullName } });
  };

  useEffect(() => {
    if (!data) return;
    setRegion(data);
  }, [data]);

  useEffect(() => {
    if (isError) return toast.error('A aparut o problema, va rugam sa incercati mai tarziu.');
    if (isSuccess) return toast.success('Regiuna a fost actualizata cu succes!');
  }, [isSuccess, isError]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {getLoading || !region ? (
        <p>Loading...</p>
      ) : (
        <MDBox py={3} dipslay="flex" flexDirection="column">
          <MDBox mb={3} sx={{ flex: 1, marginRight: '12px' }}>
            <MDInput
              label="Nume"
              name="name"
              value={region.name}
              onChange={(e) => {
                setRegion({ ...region, name: e.target.value });
              }}
              fullWidth
            />
          </MDBox>
          <MDBox mb={3} sx={{ flex: 1, marginRight: '12px' }}>
            <MDInput
              label="Nume de Adresa"
              name="fullName"
              value={region.fullName}
              onChange={(e) => {
                setRegion({ ...region, fullName: e.target.value });
              }}
              fullWidth
            />
          </MDBox>
          <MDButton loading={loadingSave} ariant="gradient" color="success" onClick={handleSubmit}>
            Salveaza
          </MDButton>
        </MDBox>
      )}
    </DashboardLayout>
  );
};

export default EditRegionScreen;
