import React, { useEffect, useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import DashboardLayout from 'components/layouts/DashboardLayout';
import DashboardNavbar from 'components/navbar/DashboardNavbar/TopNavbar';
import { useGetPollByIdQuery } from 'api/polls';
import { Link, useParams } from 'react-router-dom';
import MDBox from 'components/material/MDBox';
import MDTypography from 'components/material/MDTypography';
import MDButton from 'components/material/MDButton';
import { usePlaceVoteMutation } from 'api/polls';

import { useLazyCheckVoteQuery } from 'api/polls';

const VoteScreen = () => {
  const [userAnswers, setUserAnswers] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [votePlaced, setVotePlaced] = useState(false);
  const [placeVote, info] = usePlaceVoteMutation();
  const { id } = useParams();
  const { data, isLoading } = useGetPollByIdQuery(id);
  const [checkVote, { data: checkedVote, isLoading: isCheckLoading }] = useLazyCheckVoteQuery();

  const handleAnswerPick = (quesitonId, answerId) => {
    setUserAnswers({ ...userAnswers, [quesitonId]: answerId });
  };

  const handleSubmit = () => {
    const answers = Object.entries(userAnswers).map(([questionId, answerId]) => ({
      questionId,
      answerId,
      pollId: data.id,
    }));
    placeVote(answers);
    setVotePlaced(true);
  };

  useEffect(() => {
    const check = async () => {
      const res = await checkVote(id, false);
      if (res.data) {
        setVotePlaced(true);
      }
    };

    check();
  }, [id]);

  useEffect(() => {
    if (isLoading) return setDisabled(true);
    setDisabled(Object.keys(userAnswers).length < data.questions.length);
  }, [userAnswers]);

  if (isLoading || isCheckLoading) return <p>Loading...</p>;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {votePlaced ? (
        <MDBox
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {(info.isSuccess || checkedVote) && (
            <MDBox
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingBottom: 96,
              }}
            >
              <CheckCircleOutlineIcon style={{ width: 64, height: 64 }} color="success" />
              Votul dumneavoastra a fost inregistrat cu succes!
              <Link to="/dashboard/general">
                <MDTypography component="span" color={'blue'} opacity={0.5} sx={{ lineHeight: 0 }}>
                  Mergeti inapoi
                </MDTypography>
              </Link>
            </MDBox>
          )}
          {info.isError && (
            <MDBox
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingBottom: 96,
              }}
            >
              <WarningAmberIcon style={{ width: 64, height: 64 }} color="error" />
              Votul dumneavoastra nu a putut fi plasat. <br />
              Va rugam sa incercati mai tarziu.
              <Link to="/">
                <MDTypography component="span" color={'blue'} opacity={0.5} sx={{ lineHeight: 0 }}>
                  Mergeti inapoi
                </MDTypography>
              </Link>
            </MDBox>
          )}
        </MDBox>
      ) : (
        <MDBox
          style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
        >
          <MDTypography fontSize="32px">{data.title}</MDTypography>
          <br />

          {data.questions.map((q, i) => (
            <div key={q.content} style={{ marginBottom: 36 }}>
              <MDTypography>
                {i + 1}. {q.content}
              </MDTypography>
              <FormControl>
                <RadioGroup
                  name={q.id + 'answer-radio-group'}
                  onChange={(e) => {
                    handleAnswerPick(q.id, e.target.value);
                  }}
                >
                  {q.answers.map((answer) => (
                    <FormControlLabel
                      key={answer.id}
                      value={answer.id}
                      control={<Radio />}
                      label={answer.content}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </div>
          ))}
          <MDButton color="success" onClick={handleSubmit} disabled={disabled}>
            Plaseaza votul
          </MDButton>
        </MDBox>
      )}
    </DashboardLayout>
  );
};

export default VoteScreen;
