import { useSelector } from 'react-redux';

import { useGetUsersQuery } from 'api/user';

export const useGetUsers = () => {
  const { searchTerm, searchRegion, searchCounty } = useSelector((state) => state.user);
  const users = useGetUsersQuery({
    search: searchTerm,
    region: searchRegion,
    county: searchCounty,
  });

  return users;
};
