import { setCurrentUser } from 'store/userSlice';
import { setUsers, setCounties } from 'store/userSlice';
import { baseApi } from './index';

export const userApiSlice = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: ({ search, region, county }) => {
        let url = `/user?search=${search}`;
        if (region) url += `&regionId=${region}`;
        if (county) url += `&countyId=${county}`;

        return url;
      },
      async onQueryStarted(_arg, { queryFulfilled, dispatch }) {
        const res = await queryFulfilled;
        dispatch(setUsers(res.data.entities));
      },
      providesTags: ['User'],
    }),

    getCounties: builder.query({
      query: () => '/county',
      async onQueryStarted(_arg, { queryFulfilled, dispatch }) {
        const res = await queryFulfilled;
        dispatch(setCounties(res.data));
      },
    }),

    getUserById: builder.query({
      query: (id) => `/user/${id}`,
      async onQueryStarted(_arg, { queryFulfilled, dispatch }) {
        const res = await queryFulfilled;
        dispatch(setCurrentUser(res.data));
      },
    }),

    createUser: builder.mutation({
      query: (userData) => ({
        url: '/user',
        method: 'POST',
        body: userData,
      }),
    }),

    updateUser: builder.mutation({
      query: ({ id, data }) => {
        return {
          url: `/user/${id}`,
          method: 'PATCH',
          body: data,
        };
      },
      invalidatesTags: ['User'],
    }),

    deleteUser: builder.mutation({
      query: ({ id, body }) => ({
        url: `/user/${id}`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: ['User'],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetCountiesQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useGetUserByIdQuery,
} = userApiSlice;
