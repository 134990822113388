import { useState, useEffect } from 'react';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import { toast } from 'react-toastify';
import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import MultipleSelectChip from 'components/common/MultiSelect';

import MDBox from 'components/material/MDBox';
import MDInput from 'components/material/MDInput';
import MDButton from 'components/material/MDButton';
import { useCreatePollMutation } from 'api/polls';
import { useGetAccessibleCountiesQuery } from 'api/county';
import { TextField } from '@mui/material';

const DEFAULT_POLL = {
  title: '',
  description: '',
  public: false,
  counties: [],
};

export const CreatePollForm = () => {
  const [pollData, setPollData] = useState(DEFAULT_POLL);
  const [questionTitle, setQuestionTitle] = useState('');
  const [answerText, setAnswerText] = useState('');
  const [questions, setQuestions] = useState([]);
  const [editableQuestion, setEditableQuestion] = useState(null);
  const [editingAnswers, setEditingAnswers] = useState(null);
  const [createPoll, createPollStatus] = useCreatePollMutation();

  const { data: counties, isLoading: loadingCounties } = useGetAccessibleCountiesQuery();

  useEffect(() => {
    const { isSuccess, isError } = createPollStatus;
    if (isError) return toast.error('A aparut o problema. Va rugam incercati mai tarziu.');
    if (isSuccess) {
      cleanup();
      toast.success('Sesiunea de vot creata cu succes!');
    }
  }, [createPollStatus]);

  const addQuestionWithAnswers = () => {
    const answerContents = answerText.replace(/\s/g, '').split(',');
    setQuestions([
      ...questions,
      {
        id: 'question' + questions.length,
        content: questionTitle,
        answers: answerContents.map((ac) => ({ content: ac })),
      },
    ]);
    setQuestionTitle('');
    setAnswerText('');
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPollData({ ...pollData, [name]: value });
  };

  const editQuestionContent = (id, value) => {
    const questionsCopy = [...questions];
    const index = questionsCopy.findIndex((q) => q.id === id);
    questionsCopy[index].content = value;
    setQuestions(questionsCopy);
  };

  const saveEditedAnswers = (id) => {
    const questionsCopy = [...questions];
    const index = questionsCopy.findIndex((q) => q.id === id);
    const answersContents = editingAnswers.replace(/\s/g, '').split(',');
    questionsCopy[index].answers = answersContents.map((ac) => ({ content: ac }));
    setQuestions(questionsCopy);
    setEditingAnswers(null);
    setEditableQuestion(null);
  };

  const cleanup = () => {
    setEditableQuestion(null);
    setEditingAnswers(null);
    setAnswerText('');
    setQuestionTitle('');
    setPollData(DEFAULT_POLL);
    setQuestions([]);
  };

  const handleSubmit = () => {
    createPoll({
      ...pollData,
      questions: questions.map((q) => ({ content: q.content, answers: q.answers })),
      counties: pollData.counties.map((c) => c.id),
    });
  };

  return (
    <MDBox dipslay="flex" flexDirection="column">
      Informatii generale
      <MDBox sx={{ display: 'flex', flexDirection: 'column' }} mt={3}>
        <MDBox mb={6} sx={{ width: '50%', marginRight: '12px' }}>
          <MDInput
            label="Titlu Poll"
            name="title"
            value={pollData.title}
            onChange={handleInputChange}
            fullWidth
          />
        </MDBox>
        <MDBox mb={3} sx={{ width: '50%', marginRight: '12px', height: '100px' }}>
          <TextField
            label="Descriere"
            multiline={true}
            rows={4}
            name="description"
            value={pollData.description}
            onChange={handleInputChange}
            fullWidth
          />
        </MDBox>
      </MDBox>
      <br />
      Adauga intreberi
      <MDBox pt={1} dipslay="flex" flexDirection="column">
        {questions.map((q, index) => (
          <MDBox key={q.id} sx={{ display: 'flex' }} mt={3}>
            <MDBox mb={3} sx={{ flex: 3, marginRight: '12px' }}>
              <MDInput
                label={`Intrebare ${index + 1}`}
                name={q.id}
                value={q.content}
                disabled={editableQuestion !== q.id}
                onChange={(e) => {
                  editQuestionContent(q.id, e.target.value);
                }}
                fullWidth
              />
            </MDBox>
            <MDBox mb={3} sx={{ flex: 3, marginRight: '12px' }}>
              <MDInput
                label={'Variante raspuns'}
                name={q.id}
                disabled={editableQuestion !== q.id}
                value={editingAnswers || q.answers.map((a) => a.content).join(', ')}
                onChange={(e) => {
                  setEditingAnswers(e.target.value);
                }}
                fullWidth
              />
            </MDBox>
            <MDBox mb={3} sx={{ marginRight: '12px' }}>
              {q.id !== editableQuestion ? (
                <IconButton
                  color={'primary'}
                  onClick={() => {
                    setEditableQuestion(q.id);
                    setEditingAnswers(q.answers.map((a) => a.content).join(', '));
                  }}
                >
                  <Icon>edit</Icon>
                </IconButton>
              ) : (
                <MDButton
                  color="success"
                  onClick={() => {
                    saveEditedAnswers(q.id);
                  }}
                >
                  Salveaza
                </MDButton>
              )}
            </MDBox>
          </MDBox>
        ))}
        <MDBox sx={{ display: 'flex' }} mt={3}>
          <MDBox mb={3} sx={{ flex: 3, marginRight: '12px' }}>
            <MDInput
              label="Introduceti intrebarea"
              name="questionTitle"
              value={questionTitle}
              onChange={(e) => {
                setQuestionTitle(e.target.value);
              }}
              fullWidth
            />
          </MDBox>
          <MDBox mb={3} sx={{ flex: 3, marginRight: '12px' }}>
            <MDInput
              label="Introduceti raspunsuri"
              name="questionTitle"
              value={answerText}
              onChange={(e) => {
                setAnswerText(e.target.value);
              }}
              fullWidth
            />
            <p style={{ fontSize: 14, color: 'gray' }}>
              Introduceti variantele de raspuns delimitate de{' '}
              <span style={{ fontWeight: 'bold' }}>"," (virgula)</span>
            </p>
          </MDBox>
          <MDBox mb={3} sx={{ marginRight: '12px' }}>
            <MDButton
              color="primary"
              onClick={addQuestionWithAnswers}
              disabled={questionTitle === '' || answerText === ''}
            >
              Adauga
            </MDButton>
          </MDBox>
        </MDBox>
        Restrictioneaza accesul
        <MDBox sx={{ display: 'flex' }} mt={3}>
          <MDBox mb={3} sx={{ marginRight: '12px' }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={pollData.counties.length}
                    value={pollData.public && !pollData.counties.length}
                    onChange={(e) => {
                      setPollData({ ...pollData, public: e.target.checked });
                    }}
                  />
                }
                label="Acces public"
              />
            </FormGroup>
          </MDBox>
          <MDBox style={{ marginLeft: 24, width: '100%', maxWidth: 520 }}>
            {!loadingCounties && (
              <MultipleSelectChip
                disabled={pollData.public}
                value={pollData.counties.map((c) => c?.id)}
                label={'Alegeti regiuni'}
                options={counties.map((c) => ({ key: c.name, value: c.id }))}
                handleChange={(e) => {
                  const {
                    target: { value },
                  } = e;

                  const valueArr = value === 'string' ? value.split(',') : value;
                  setPollData({
                    ...pollData,
                    counties: counties.filter((c) => valueArr.includes(c.id)),
                  });
                }}
              />
            )}
          </MDBox>
        </MDBox>
      </MDBox>
      <MDButton color="success" onClick={handleSubmit}>
        Creeaza Poll
      </MDButton>
      <br />
    </MDBox>
  );
};
