/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from 'react';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// Custom styles for MDInput
import MDInputRoot from 'components/material/MDInput/MDInputRoot';

const MDInput = forwardRef(
  ({ error, success, disabled, multiline, sx, inputRef, ...rest }, ref) => (
    <MDInputRoot
      sx={sx}
      inputRef={inputRef}
      multiline={multiline}
      autoComplete={'off'}
      {...rest}
      ref={inputRef ? (ref) => (inputRef.current = ref) : ref}
      ownerState={{ error, success, disabled }}
    />
  )
);

// Setting default values for the props of MDInput
MDInput.defaultProps = {
  error: false,
  success: false,
  disabled: false,
  sx: {},
};

// Typechecking props for the MDInput
MDInput.propTypes = {
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
  sx: PropTypes.object,
  style: PropTypes.object,
};

export default MDInput;
