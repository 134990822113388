import React, { useEffect, useMemo, useState } from 'react';

import MDBox from 'components/material/MDBox';
import MDInput from 'components/material/MDInput';
import MDButton from 'components/material/MDButton';
import { useGetCountiesQuery } from 'api/user';
import moment from 'moment';
import { useCreateUserMutation, useUpdateUserMutation } from 'api/user';
import { toast } from 'react-toastify';
import MultipleSelectChip from 'components/common/MultiSelect';
import BasicSelect from 'components/common/Select';

import { useParams } from 'react-router-dom';
import { useGetUserByIdQuery } from 'api/user';
import { Loader } from 'components/common/Loader';
import { useGetRegionsQuery } from 'api/region';

const DEFAULT_MEMBER = {
  name: '',
  email: '',
  userName: '',
  counties: [],
  roles: [{ role: 'member' }],
  password: '',
  regionId: 0,
  userProfile: {
    address: '',
    joinDate: moment().format('YYYY-MM-DD'),
    region: '',
    cnp: '',
  },
};

const CreateUserForm = () => {
  const { data: counties, isLoading: countiesLoading } = useGetCountiesQuery();
  const { data: regions, isLoading: regionsLoading } = useGetRegionsQuery();
  const [member, setMember] = useState(DEFAULT_MEMBER);

  const { id: userId } = useParams();

  const [createUser, { isLoading: loadingCreate, isError: createError, isSuccess: created }] =
    useCreateUserMutation();
  const [updateUser, { isLoading: loadingUpdate, isError: updateError, isSuccess: updated }] =
    useUpdateUserMutation();

  const { data: defaultData, isLoading: userLoading } = useGetUserByIdQuery(userId);

  const rolesOptions = [
    { key: 'Administrator', value: 'admin' },
    { key: 'Super Admin', value: 'superAdmin' },
    { key: 'Membru', value: 'member' },
  ];

  const loading = useMemo(() => loadingCreate || loadingUpdate, [loadingCreate, loadingUpdate]);

  useEffect(() => {
    if (createError || updateError) {
      toast.error('A aparut o problema. Va rugam incercati mai tarziu.');
    }
  }, [createError, updateError]);

  useEffect(() => {
    if (created) toast.success('Membru adaugat cu succes!');
  }, [created]);

  useEffect(() => {
    if (updated) toast.success('Membru actualizat cu succes!');
  }, [updated]);

  useEffect(() => {
    if (!defaultData) return;

    let data = {
      ...defaultData,
      userProfile: {
        ...defaultData.userProfile,
        joinDate: defaultData.userProfile.joinDate
          ? moment(defaultData.userProfile.joinDate).format('YYYY-MM-DD')
          : null,
      },
    };

    setMember(data);
  }, [defaultData]);

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name.includes('.')) {
      setMember({
        ...member,
        userProfile: { ...member.userProfile, [name.split('.')[1]]: value },
      });
    } else {
      setMember({ ...member, [name]: value });
    }
  };

  const handleSubmit = () => {
    if (userId) {
      const { password, ...memberData } = member;

      updateUser({
        id: defaultData.id,
        data: {
          ...memberData,
          userProfile: {
            ...memberData.userProfile,
            joinDate: member.userProfile.joinDate
              ? new Date(`${member.userProfile.joinDate}T00:00:00`).toISOString()
              : null,
          },
          counties: memberData.counties.map((c) => c.id),
        },
      });
    } else {
      createUser({
        ...member,
        userName: member.userProfile.cnp,
        counties: member.counties.map((c) => c.id),
        password: member.userProfile.cnp,
      });
      setMember(DEFAULT_MEMBER);
    }
  };

  if (userLoading) return <Loader />;

  return (
    <MDBox py={3} dipslay="flex" flexDirection="column">
      {userId ? 'Editeaza date membru' : 'Adauga utilizator nou'}
      <MDBox sx={{ display: 'flex' }} mt={3}>
        <MDBox mb={3} sx={{ flex: 1, marginRight: '12px' }}>
          <MDInput
            label="Nume si prenume"
            name="name"
            value={member.name}
            onChange={handleInputChange}
            fullWidth
          />
        </MDBox>
        <MDBox mb={3} sx={{ flex: 1, marginLeft: '24px' }}>
          {!regionsLoading && (
            <BasicSelect
              label="Regiune"
              value={member.regionId}
              options={regions.map((region) => ({
                key: region.name,
                value: region.id,
              }))}
              handleChange={(region) => {
                setMember({ ...member, regionId: region });
              }}
            />
          )}
        </MDBox>
      </MDBox>
      <MDBox sx={{ display: 'flex' }}>
        <MDBox mb={3} sx={{ flex: 1, marginRight: '12px' }}>
          <MDInput
            label="CNP"
            name="userProfile.cnp"
            onChange={handleInputChange}
            value={member.userProfile.cnp}
            fullWidth
          />
        </MDBox>
        <MDBox mb={3} sx={{ flex: 1, marginLeft: '12px' }}>
          <MDInput
            label="Adresa email"
            name="email"
            value={member.email}
            onChange={handleInputChange}
            type="email"
            fullWidth
          />
        </MDBox>
      </MDBox>
      <MDBox sx={{ display: 'flex' }}>
        <MDBox mb={3} sx={{ width: '50%', marginRight: '12px' }}>
          <MDInput
            label="Adresa"
            name="userProfile.address"
            value={member.userProfile.address}
            onChange={handleInputChange}
            fullWidth
          />
        </MDBox>
        <MDBox mb={3} sx={{ width: '50%', marginLeft: '12px' }}>
          <MDInput
            label="Data aderarii"
            placeholder=""
            type="date"
            fullWidth
            name="userProfile.joinDate"
            value={member.userProfile.joinDate}
            onChange={handleInputChange}
          />
        </MDBox>
      </MDBox>
      <MDBox sx={{ display: 'flex' }}>
        <MDBox mb={3} sx={{ flex: 1, marginRight: '12px' }}>
          <BasicSelect
            label="Rol"
            value={member.roles.length ? member.roles[0].role : 'member'}
            options={rolesOptions}
            handleChange={(role) => {
              setMember({ ...member, roles: [{ role }] });
            }}
          />
        </MDBox>
        <MDBox mb={3} sx={{ flex: 1, marginLeft: '12px' }}>
          {!countiesLoading && (
            <MultipleSelectChip
              value={member.counties.map((c) => c?.id)}
              label={'Judet/e'}
              options={counties.map((c) => ({ key: c.name, value: c.id }))}
              handleChange={(e) => {
                const {
                  target: { value },
                } = e;

                const valueArr = value === 'string' ? value.split(',') : value;
                setMember({ ...member, counties: counties.filter((c) => valueArr.includes(c.id)) });
              }}
            />
          )}
        </MDBox>
      </MDBox>
      <MDButton loading={loading} ariant="gradient" color="info" onClick={handleSubmit}>
        {defaultData ? 'Salveaza' : 'Creeaza membru'}
      </MDButton>
    </MDBox>
  );
};

export default CreateUserForm;
