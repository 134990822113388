import React, { useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import styled from 'styled-components';
import Spinner from 'react-spinners/BeatLoader';
import { toast } from 'react-toastify';

import BasicSelect from 'components/common/Select';
import MDTypography from 'components/material/MDTypography';
import MDButton from 'components/material/MDButton';
import BasicTable from 'components/common/Table';
import DashboardLayout from 'components/layouts/DashboardLayout';
import DashboardNavbar from 'components/navbar/DashboardNavbar/TopNavbar';
import { useRegistry } from 'hooks/useRegistry';
import MDBox from 'components/material/MDBox';
import { useGetRegionsQuery } from 'api/region';
import { setRegistryFilters } from 'store/registrySlice';
import { useDispatch } from 'react-redux';
import { downloadBuffer } from 'services/downloadFile';

const StyledContainer = styled.div`
  background-color: white;
  border-radius: 8px;
`;

const RegistryScreen = () => {
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({ region: null });
  const { columns, rows, isLoading } = useRegistry();
  const { access_token } = useSelector((state) => state.auth);

  const { data: regions, isLoading: regionsLoading } = useGetRegionsQuery();

  const dispatch = useDispatch();

  const handleSearchClick = () => {
    dispatch(setRegistryFilters(filters));
  };

  const handleClearFilters = () => {
    setFilters({ region: null });
    dispatch(setRegistryFilters({ region: 0 }));
  };

  const handleCreateAddress = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/registry/address`,
        { regionId: filters.region },
        {
          responseType: 'arraybuffer',
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );

      downloadBuffer(response.data, 'pdf', 'Adresa');
    } catch (error) {
      if (error.response.status === 404) {
        toast.warning('Nu exita inregistrari pentru a creea adresa dorita!');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <StyledContainer>
        <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
          <MDBox>
            <MDTypography variant="h6" gutterBottom>
              Registru intrari iesiri
            </MDTypography>
          </MDBox>
          <MDBox display="flex" alignItems="center">
            {!regionsLoading && (
              <BasicSelect
                label="Regiune"
                style={{ width: 160, background: '#f0f2f5', marginRight: 16 }}
                value={filters.region}
                options={[{ name: 'Toate regiunile', id: null }, ...regions].map((region) => ({
                  key: region.name,
                  value: region.id,
                }))}
                handleChange={(region) => {
                  setFilters({ ...filters, region });
                }}
              />
            )}

            <MDButton style={{ marginLeft: 16 }} color="primary" onClick={handleSearchClick}>
              <SearchIcon />
              Cauta
            </MDButton>
            <MDButton variant="text" color="primary" onClick={handleClearFilters}>
              Reseteaza
            </MDButton>
            <MDButton style={{ marginLeft: 48 }} color="success" onClick={handleCreateAddress}>
              {!loading && <FileDownloadIcon fontSize="small" />}
              {loading ? <Spinner size={12} color={'white'} /> : 'Creeaza adresa'}
            </MDButton>
          </MDBox>
        </MDBox>
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <BasicTable pageSize={15} rows={rows} reversePages={true} columns={columns} />
        )}
      </StyledContainer>
    </DashboardLayout>
  );
};

export default RegistryScreen;
