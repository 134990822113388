import { Route, Routes } from 'react-router-dom';

import Dashboard from '../screens/dashboard';

import SignIn from '../screens/authentication/sign-in';
import SignUp from '../screens/authentication/sign-up';
import DashboardOutlet from './DashboardOutelet';
import CreateUserScreen from 'screens/users/CreateUserScreen';
import UserScreen from 'screens/users/UserScreen';
import PollsScreen from 'screens/polls/PollsScreen';
import CreatePollScreen from 'screens/polls/CreatePollScreen';
import MemberDashboardScreen from 'screens/dashboard/MemberDashboardScreen';
import RegrionsScreen from 'screens/regions/RegionsScreen';
import EditRegionScreen from 'screens/regions/EditRegionScreen';
import VoteScreen from 'screens/polls/VoteScreen';
import PollDetailsScreen from 'screens/polls/PollDetailsScreen';
import RegistryScreen from 'screens/registry/RegistryScreen';
import AddressesScreen from 'screens/addresses/AddressesScreen';
import Home from 'screens/HomeScreen';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Home />} />
      <Route path="/dashboard" element={<DashboardOutlet />}>
        <Route path="" element={<Dashboard />} />
        <Route path="addresses" element={<AddressesScreen />} />
        <Route path="registry" element={<RegistryScreen />} />
        <Route path="general" element={<MemberDashboardScreen />} />
        <Route path="create-user" element={<CreateUserScreen />} />
        <Route path="polls" element={<PollsScreen />} />
        <Route path="regions" element={<RegrionsScreen />} />
        <Route path="regions/edit/:id" element={<EditRegionScreen />} />
        <Route path="polls/create" element={<CreatePollScreen />} />
        <Route path="polls/vote/:id" element={<VoteScreen />} />
        <Route path="polls/report/:id" element={<PollDetailsScreen />} />
        <Route path="user/:id" element={<UserScreen />} />
      </Route>
      <Route path="/authentication/signin" element={<SignIn />} />
      <Route path="/authentication/signup" element={<SignUp />} />
    </Routes>
  );
};

export default AppRoutes;
