import { baseApi } from './index';
import { setCheckLoading } from 'store/authSlice';

export const authApiSplice = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: '/auth/login',
        method: 'POST',
        body: credentials,
      }),
      invalidatesTags: ['User', 'Poll'],
    }),

    getUserProfile: builder.query({
      query: () => `/auth/check`,
      async onQueryStarted(_arg, { queryFulfilled, dispatch }) {
        dispatch(setCheckLoading(true));
        await queryFulfilled;
        dispatch(setCheckLoading(false));
      },
    }),
  }),
});

export const { useLoginMutation, useGetUserProfileQuery } = authApiSplice;
