import { useMemo } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const colors = [
  'rgba(255, 99, 132, 0.2)',
  'rgba(54, 162, 235, 0.2)',
  'rgba(255, 206, 86, 0.2)',
  'rgba(75, 192, 192, 0.2)',
  'rgba(153, 102, 255, 0.2)',
  'rgba(255, 159, 64, 0.2)',
];

const borderColors = [
  'rgba(255, 99, 132, 1)',
  'rgba(54, 162, 235, 1)',
  'rgba(255, 206, 86, 1)',
  'rgba(75, 192, 192, 1)',
  'rgba(153, 102, 255, 1)',
  'rgba(255, 159, 64, 1)',
];

const QuestionChart = ({ question, votes }) => {
  const chartData = useMemo(() => {
    if (!question) return;

    const answers = question.answers;
    const filteredVotes = votes.filter((v) => v.questionId === question.id);

    let data = [];
    answers.forEach((a) => {
      const answerVotes = filteredVotes.filter((vote) => vote.answerId === a.id);
      data.push(answerVotes.length);
    });

    return {
      labels: [...answers.map((a) => a.content)],
      datasets: [
        {
          label: '# of Votes',
          data,
          backgroundColor: colors.slice(0, question.answers.length),
          borderColor: borderColors.slice(0, question.answers.length),
          borderWidth: 1,
        },
      ],
    };
  }, [question, votes]);

  return <Pie data={chartData} />;
};

export default QuestionChart;
