import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import './assets/styles/toast.css';

import theme from 'assets/theme';

import themeDark from 'assets/theme-dark';

import { useMaterialUIController } from 'context';
import AppRoutes from 'router/AppRouter';

export default function App() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        className={'toast-message'}
      />
      <CssBaseline />

      <AppRoutes />
    </ThemeProvider>
  );
}
