export const downloadBuffer = async (data, extension, name = 'data') => {
  try {
    const url = URL.createObjectURL(
      new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
    );

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${name}.${extension}`);

    document.body.appendChild(link);
    link.click();

    link.parentNode.removeChild(link);
    URL.revokeObjectURL(url);
  } catch (error) {
    console.log('Err', error);
  }
};
