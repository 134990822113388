import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Outlet, useNavigate } from 'react-router-dom';
import { useMaterialUIController, setMiniSidenav } from 'context';
import routes from 'routes';

import { useSelector } from 'react-redux';
import Sidenav from 'components/sidenav';
import brandDark from 'assets/images/logo-ct-dark.png';
import brandWhite from 'assets/images/logo-ct.png';

const StyledContainer = styled.div`
  height: 100vh;
`;

const DashboardOutlet = () => {
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const authState = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    console.log(authState.access_token);
    if (!authState.access_token) {
      navigate('/authentication/signin');
    }
  }, [authState]);

  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, sidenavColor, transparentSidenav, whiteSidenav, darkMode } = controller;

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  return (
    <StyledContainer>
      <Sidenav
        color={sidenavColor}
        brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
        brandName="Material Dashboard 2"
        routes={routes}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      />
      <Outlet />
    </StyledContainer>
  );
};

export default DashboardOutlet;
