import { Link } from 'react-router-dom';

import MDButton from 'components/material/MDButton';
import styled from 'styled-components';

const StyledContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const HomeScreen = () => {
  return (
    <StyledContainer>
      Sindicatul National Solidaritatea
      <MDButton component={Link} to={'/dashboard'} size="small">
        Intra in SNS
      </MDButton>
    </StyledContainer>
  );
};

export default HomeScreen;
