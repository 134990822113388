import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';

import { useGetPollsQuery } from 'api/polls';
import MDButton from 'components/material/MDButton';
import MDBox from 'components/material/MDBox';
import MDTypography from 'components/material/MDTypography';

import { useDeletePollMutation } from 'api/polls';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useUpdatePollMutation } from 'api/polls';
import { truncate } from 'utils/strings';
import { useSelector } from 'react-redux';
import { UserRoles } from 'constants/UserRoles';
import BasicTable from 'components/common/Table';

const PollsTable = ({ memberView }) => {
  const { data, error, isLoading } = useGetPollsQuery();
  const [deletePoll, deleteStatus] = useDeletePollMutation();
  const [updatePoll, updateStatus] = useUpdatePollMutation();
  const [wasOpened, setWasOpened] = useState(false);
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (deleteStatus.isLoading) return;
    if (deleteStatus.isError) toast.error('A aparut o problema, va rugam incercati mai tarziu.');
    if (deleteStatus.isSuccess) toast.success('Poll sters cu succes!');
  }, [deleteStatus]);

  useEffect(() => {
    if (updateStatus.isLoading) return;
    if (updateStatus.isError)
      toast.error('A aparut o problema cu update, va rugam incercati mai tarziu.');
    if (updateStatus.isSuccess) {
      if (wasOpened) toast.success('Poll redeschis cu succes!');
      else toast.success('Poll-ul a fost inchis.');
    }
  }, [updateStatus]);

  const defaultColumns = [
    { Header: '#', accessor: 'id', width: '5%', align: 'left' },
    { Header: 'titlu', accessor: 'title', align: 'left' },
    { Header: 'descriere', accessor: 'description', width: '25%', align: 'center' },
    { Header: 'intrebari', accessor: 'questions', width: '35%', align: 'center' },
    { Header: 'deschis in', accessor: 'createdAt', align: 'center' },
  ];

  const tableData = {
    isLoading,
    error,
    columns: user?.roles.map((r) => r.role).includes(UserRoles.Member)
      ? [...defaultColumns, { Header: 'actiuni', accessor: 'actions', align: 'center' }]
      : [
          ...defaultColumns,
          { Header: 'detalii', accessor: 'report', align: 'center' },
          { Header: 'actiuni', accessor: 'actions', align: 'center' },
        ],
    rows: data?.map((poll) => {
      const row = {
        id: (
          <MDTypography component="p" variant="caption" color="text" fontWeight="medium">
            {poll.id}
          </MDTypography>
        ),
        title: (
          <MDTypography component="p" variant="caption" color="text" fontWeight="medium">
            {poll.title}
          </MDTypography>
        ),
        description: (
          <MDTypography component="p" variant="caption" color="text" fontWeight="medium">
            {truncate(poll.description, 50)}
          </MDTypography>
        ),
        questions: (
          <ul>
            {poll.questions.map((q) => (
              <MDTypography
                key={q.content}
                component="p"
                variant="caption"
                color="text"
                fontWeight="medium"
              >
                {q.content}
              </MDTypography>
            ))}
          </ul>
        ),
        createdAt: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {new Date(poll.createdAt).toLocaleDateString()}
          </MDTypography>
        ),
        actions: !memberView ? (
          <div>
            <IconButton
              color={poll.opened ? 'success' : 'primary'}
              onClick={() => {
                setWasOpened(!poll.opened);
                updatePoll({ id: poll.id, data: { opened: !poll.opened } });
              }}
            >
              {poll.opened ? <Icon>lock_open</Icon> : <Icon>lock</Icon>}
            </IconButton>
            <IconButton
              color={'error'}
              onClick={() => {
                deletePoll(poll.id);
              }}
            >
              <Icon>delete</Icon>
            </IconButton>
          </div>
        ) : (
          <MDButton
            variant={'text'}
            color={'primary'}
            onClick={() => {
              navigate('/dashboard/polls/vote/' + poll.id);
            }}
          >
            Voteaza
          </MDButton>
        ),
      };

      if (!user.roles.includes(UserRoles.Member)) {
        row.report = (
          <MDButton
            variant={'text'}
            color={'primary'}
            onClick={() => {
              navigate('/dashboard/polls/report/' + poll.id);
            }}
          >
            Detalii
          </MDButton>
        );
      }

      return row;
    }),
  };

  const renderMenu = (
    <MDButton
      color="primary"
      onClick={() => {
        navigate('/dashboard/polls/create');
      }}
    >
      Adauga
    </MDButton>
  );

  if (isLoading) return <p>Loading ...</p>;

  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox>
          <MDTypography variant="h6" gutterBottom>
            Poll-uri
          </MDTypography>
        </MDBox>
        {!memberView ? renderMenu : null}
      </MDBox>
      <MDBox>
        <BasicTable rows={tableData.rows} columns={tableData.columns} />
      </MDBox>
    </Card>
  );
};

export default PollsTable;
