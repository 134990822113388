import React from 'react';

import DashboardLayout from 'components/layouts/DashboardLayout';
import DashboardNavbar from 'components/navbar/DashboardNavbar/TopNavbar';

import CreateUserForm from 'components/user/CreateUserForm';

const CreateUserScreen = () => {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <CreateUserForm />
    </DashboardLayout>
  );
};

export default CreateUserScreen;
