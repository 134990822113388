import React, { useEffect } from 'react';

import DashboardLayout from 'components/layouts/DashboardLayout';
import DashboardNavbar from 'components/navbar/DashboardNavbar/TopNavbar';

import CreateUserForm from 'components/user/CreateUserForm';
import { useDispatch } from 'react-redux';
import { setCurrentUser } from 'store/userSlice';

const UserScreen = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(setCurrentUser(null));
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <CreateUserForm />
    </DashboardLayout>
  );
};

export default UserScreen;
