import { baseApi } from './index';

export const pollApiSlice = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getPolls: builder.query({
      query: () => '/poll',
      providesTags: ['Poll'],
    }),

    getPollById: builder.query({
      query: (id) => `/poll/${id}`,
    }),

    createPoll: builder.mutation({
      query: (data) => ({
        url: '/poll',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Poll'],
    }),

    updatePoll: builder.mutation({
      query: ({ id, data }) => ({
        url: `/poll/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['Poll'],
    }),

    deletePoll: builder.mutation({
      query: (id) => ({
        url: `/poll/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Poll'],
    }),

    placeVote: builder.mutation({
      query: (data) => {
        return {
          url: '/poll/vote',
          method: 'POST',
          body: data,
        };
      },
    }),

    checkVote: builder.query({
      query: (pollId) => `/poll/vote/${pollId}`,
    }),
  }),
});

export const {
  useGetPollsQuery,
  useGetPollByIdQuery,
  useCreatePollMutation,
  useDeletePollMutation,
  useUpdatePollMutation,
  usePlaceVoteMutation,
  useCheckVoteQuery,
  useLazyCheckVoteQuery,
} = pollApiSlice;
