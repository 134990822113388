import React from 'react';

import DashboardLayout from 'components/layouts/DashboardLayout';
import DashboardNavbar from 'components/navbar/DashboardNavbar/TopNavbar';
import { useParams } from 'react-router-dom';
import { useGetPollByIdQuery } from 'api/polls';
import MDBox from 'components/material/MDBox';
import MDTypography from 'components/material/MDTypography';
import QuestionChart from './components/QuestionChart';

const PollDetailsScreen = () => {
  const { id } = useParams();
  const { data: poll, isLoading } = useGetPollByIdQuery(id);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <>
          {poll.questions.map((q, i) => (
            <MDBox key={q.id}>
              <MDTypography>
                {i + 1}.{q.content}
              </MDTypography>
              <MDBox style={{ height: 400 }}>
                <QuestionChart question={q} votes={poll.votes} />
              </MDBox>
            </MDBox>
          ))}
        </>
      )}
    </DashboardLayout>
  );
};

export default PollDetailsScreen;
