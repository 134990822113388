import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// @mui material components
import Card from '@mui/material/Card';

// Material Dashboard 2 React components
import MDBox from 'components/material/MDBox';
import MDTypography from 'components/material/MDTypography';
import MDInput from 'components/material/MDInput';
import MDButton from 'components/material/MDButton';
import styled from 'styled-components';

import { useLoginMutation } from '../../../api/auth';
import { useDispatch } from 'react-redux';
import { setCredentials } from 'store/authSlice';

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
`;

function Basic() {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [password, setPassword] = useState('');
  const [login, { isLoading }] = useLoginMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogin = async () => {
    const res = await login({ email, password });

    if (res.error) {
      if (res.error.status === 401)
        setErrorMessage('Email sau parola greista. Va rugam reincercati.');
      else setErrorMessage('A aparut o problema, va rugam incercati mai tarziu.');
    } else {
      dispatch(setCredentials(res.data));
      navigate('/dashboard', { replace: true });
    }
  };

  return (
    <Container>
      <Card style={{ width: '100%', maxWidth: '500px' }}>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Autentifică-te
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                fullWidth
                value={email}
                type="email"
                sx={{ border: '1px solid #D3D3D3' }}
                label="Utilizator / CNP"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Parolă"
                fullWidth
                sx={{ border: '1px solid #D3D3D3' }}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </MDBox>
            {errorMessage && <p style={{ fontSize: 16, color: 'red' }}>{errorMessage}</p>}
            <MDBox mt={4} mb={1}>
              <MDButton
                fullWidth
                color="info"
                variant="gradient"
                loading={isLoading}
                onClick={handleLogin}
              >
                Continua
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                V-ati uitat parola ?{' '}
                <MDTypography
                  component={Link}
                  to="/authentication/forgot-password"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Recupereaza parola
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </Container>
  );
}

export default Basic;
