import { useGetRegionsQuery } from '../api/region';
import MDTypography from 'components/material/MDTypography';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import { useNavigate } from 'react-router-dom';

export const useRegions = () => {
  const { data, isLoading } = useGetRegionsQuery();
  const navigate = useNavigate();

  const handleEdit = (region) => {
    navigate(`/dashboard/regions/edit/${region.id}`);
  };

  return {
    isLoading,
    columns: [
      { Header: 'id', accessor: 'id', align: 'left' },
      { Header: 'nume', accessor: 'name', align: 'left' },
      { Header: 'nume de adresa', accessor: 'fullName' },
      { Header: 'membrii', accessor: 'userCount', aligh: 'left' },
      { Header: 'actiuni', accessor: 'actions', aligh: 'left' },
    ],
    rows: isLoading
      ? []
      : data.map((region, index) => ({
          entry: {
            id: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {index + 1}
              </MDTypography>
            ),
            name: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {region.name}
              </MDTypography>
            ),
            fullName: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {region.fullName || region.name}
              </MDTypography>
            ),
            userCount: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {region.usercount}
              </MDTypography>
            ),
            actions: (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <IconButton
                  color={'primary'}
                  onClick={() => {
                    handleEdit(region);
                  }}
                >
                  <Icon>edit</Icon>
                </IconButton>

                <IconButton color={'error'}>
                  <Icon>delete</Icon>
                </IconButton>
              </div>
            ),
          },
        })),
  };
};
