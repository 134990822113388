import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(theme) {
  return {
    fontWeight: theme.typography.fontWeightRegular,
  };
}

export default function MultipleSelectChip({
  options,
  handleChange,
  label,
  value,
  sx,
  disabled,
  multiple = true,
}) {
  const theme = useTheme();

  return (
    <div>
      <FormControl sx={{ width: '100%', ...sx }}>
        <InputLabel id="demo-multiple-chip-label">{label}</InputLabel>
        <Select
          multiple={multiple}
          disabled={disabled}
          value={value}
          onChange={handleChange}
          input={
            <OutlinedInput
              style={{ backgroundColor: 'white' }}
              id="select-multiple-chip"
              label="Chip"
            />
          }
          renderValue={(selected) => {
            return (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => {
                  return (
                    <Chip
                      key={value}
                      label={
                        options.find((o) => {
                          return o?.value === value;
                        }).key
                      }
                      style={{ height: 18 }}
                    />
                  );
                })}
              </Box>
            );
          }}
          MenuProps={MenuProps}
        >
          {options.map(({ key, value }) => (
            <MenuItem key={value} value={value} style={getStyles(theme)}>
              {key}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
