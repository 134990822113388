import DashboardLayout from 'components/layouts/DashboardLayout';
import DashboardNavbar from 'components/navbar/DashboardNavbar/TopNavbar';

import BasicTable from 'components/common/Table';
import { useAddresses } from 'hooks/useAddresses';

const AddressesScreen = () => {
  const { rows, columns, isLoading } = useAddresses();

  return (
    <DashboardLayout>
      <DashboardNavbar></DashboardNavbar>
      {!isLoading && <BasicTable rows={rows} columns={columns} pageSize={100}></BasicTable>}
    </DashboardLayout>
  );
};

export default AddressesScreen;
