import { baseApi } from 'api';

const regionApiSlice = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getRegions: builder.query({
      query: () => '/region',
      providesTags: ['Region'],
    }),

    getRegionById: builder.query({
      query: (id) => `/region/${id}`,
      providesTags: ['Region'],
    }),

    createRegion: builder.mutation({
      query: (data) => ({
        url: '/region',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Region'],
    }),

    updateRegionById: builder.mutation({
      query: ({ id, data }) => ({
        url: `/region/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['Region'],
    }),
  }),
});

export const {
  useGetRegionsQuery,
  useGetRegionByIdQuery,
  useCreateRegionMutation,
  useUpdateRegionByIdMutation,
} = regionApiSlice;
