import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const baseApi = createApi({
  reducerPath: 'baseApi',
  tagTypes: ['Poll', 'User'],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const {
        auth: { access_token },
      } = getState();

      if (access_token) headers.set('Authorization', `Bearer ${access_token}`);
    },
  }),
  endpoints: () => ({}),
});
