import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
  access_token: null,
  checkLoading: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      const {
        payload: { user, access_token },
      } = action;

      state.user = user;
      state.access_token = access_token;
    },

    setUser: (state, action) => {
      const { payload } = action;
      state.user = payload;
    },

    setCheckLoading: (state, action) => {
      const { payload } = action;
      state.checkLoading = payload;
    },

    logout: (state) => {
      state.user = null;
      state.access_token = null;
      state.checkLoading = false;
    },
  },
});

export const { setCredentials, setCheckLoading, logout } = authSlice.actions;

export default authSlice.reducer;
